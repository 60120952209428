<!--
 * @Author: your name
 * @Date: 2022-03-14 10:48:15
 * @LastEditTime: 2022-05-18 15:44:55
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\editor\editor.vue
-->
<template>
  <div class="editor">
    <!-- 图片 -->
    <div class="uploadImg">
      <h4>图片</h4>
      <el-upload
        class="avatar-uploader"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img class="previewImg avatar" v-if="previewImg" :src="previewImg" />
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
      </el-upload>
    </div>
    <!-- 标题 -->
    <div class="uploadTitle">
      <span>文章标题:</span>
      <el-input v-model="updateTitle"></el-input>
    </div>
    <!-- 标签 -->
    <div class="uploadTitle">
      <span>文章类型:</span>
      <el-select placeholder="请选择标签" v-model="updateLabel">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>
    </div>
    <!-- 文章 -->
    <div class="uploadContent">
      <span>文章内容:</span>
      <md-editor
        :toolbars="[
          'bold',
          'underline',
          'italic',
          '-',
          'sub',
          'sup',
          'quote',
          'unorderedList',
          'orderedList',
          '-',
          'codeRow',
          'code',
          'link',
          'table',
          '-',
          'revoke',
          'next',
          '-',
          'preview',
          'htmlPreview',
        ]"
        editorId="md-prev4"
        :preview="false"
        style="margin-top: 20px"
        placeholder="请输入内容..."
        v-model="contentValue"
      >
      </md-editor>
    </div>
    <!-- 发表 -->
    <div class="submit">
      <el-button v-if="isLogin" type="primary" @click="debounce(submit, 500)">
        发表
      </el-button>
      <el-button v-else type="primary" @click="UP_LOGINSHOW(true)">
        登陆
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { useGetters } from "@/store/utils/useGetters.js";
import { ElNotification } from "element-plus";
import { debounce } from "@/utils/public.js";
import { onActivated, ref } from "vue";
import { createPosts, getTags } from "@/api/api.js";
import useLoading from "../../hooks/useLoading.js";
export default {
  name: "editor",
  setup() {
    let loading = useLoading();
    // 开启loading
    onActivated(() => {
      window.scrollTo(0, 0);
    });
    // 图片
    let headImage = ref("");
    // 预览图片
    let previewImg = ref("");
    // 标题
    let updateTitle = ref("");
    // 标签id
    let updateLabel = ref("");
    //文章内容
    let contentValue = ref("");
    // 标签数组
    const options = ref([]);
    // 获取标签
    getTags().then((rs) => {
      rs.data.forEach((item) => {
        options.value.push({
          value: item.id,
          label: item.name,
        });
      });
    });

    // 发表文章
    let submit = () => {
      if (headImage.value == "" && headImage.value.length === 0) {
        ElNotification({
          type: "error",
          title: `失败！`,
          message: `请上传图片！`,
          position: "bottom-right",
        });
        return;
      }

      let size = headImage.value.size / 1024 / 1024;
      if (size > 5) {
        ElNotification({
          type: "error",
          title: `失败！`,
          message: `图片不能大于5M！`,
          position: "bottom-right",
        });
        return;
      }

      if (updateTitle.value == "" && updateTitle.value.length === 0) {
        ElNotification({
          type: "error",
          title: `失败！`,
          message: `请输入文章标题！`,
          position: "bottom-right",
        });
        return;
      }

      if (updateLabel.value == "" && updateLabel.value.length === 0) {
        ElNotification({
          type: "error",
          title: `失败！`,
          message: `请选择文章标签！`,
          position: "bottom-right",
        });
        return;
      }

      if (contentValue.value == "" && contentValue.value.length === 0) {
        ElNotification({
          type: "error",
          title: `失败！`,
          message: `请输入文章内容！`,
          position: "bottom-right",
        });
        return;
      }

      const formData = new FormData();
      formData.append("title", updateTitle.value);
      formData.append("content", contentValue.value);
      formData.append("tagId", updateLabel.value);
      formData.append("file", headImage.value);
      createPosts(formData, {
        "Content-Type": "multipart/form-data",
      }).then((res) => {
        if (res.code === 7404) {
          ElNotification({
            type: "error",
            title: `失败！`,
            message: `内容不能为空！`,
            position: "bottom-right",
          });
          return;
        }

        if (res.code === 333) {
          ElNotification({
            type: "error",
            title: `失败！`,
            message: `发表失败！`,
            position: "bottom-right",
          });
        }

        if (res.code === 666) {
          ElNotification({
            type: "success",
            title: `成功！`,
            message: `发表成功！`,
            position: "bottom-right",
          });
        }
      });

      for (var value of formData.values()) {
        console.log(value);
      }
    };

    let beforeAvatarUpload = (res) => {
      headImage.value = res;
      console.log(headImage.value);
      previewImg.value = window.URL.createObjectURL(res);
    };

    return {
      submit,
      debounce,
      options,
      updateTitle,
      updateLabel,
      previewImg,
      contentValue,
      beforeAvatarUpload,
      ...mapMutations("headInfo", ["UP_ACTIVE_LINK", "UP_LOGINSHOW"]),
      ...mapMutations("userInfo", ["UP_LOGINSHOW"]),
      ...useGetters("userInfo", ["isLogin"]),
    };
  },
  activated() {
    this.UP_ACTIVE_LINK("");
  },
  created() {
    this.UP_ACTIVE_LINK("");
  },
};
</script>

<style lang="scss">
.submit {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.uploadContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  #md-prev-textarea {
    padding: 10px;
  }
}

.uploadTitle {
  width: 100%;
  display: flex;
  margin: 10px;
  span {
    display: flex;
    flex: 1;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    min-width: 70px;
  }
  .el-input,
  .el-select {
    flex: 8;
  }
}
.uploadImg {
  width: 100%;
  box-sizing: border-box;
}
.avatar-uploader .el-upload {
  margin-top: 15px;
  box-sizing: border-box;
  border: 1px solid #eee;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-height: 240px;

  transition: var(--el-transition-duration-fast);
}
.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 100%;
  max-height: 300px;
  height: 100%;
  display: block;
  box-sizing: border-box;
}
.editor {
  width: 35%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  font-size: 20px;
  font-weight: 600;
  color: #666;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .uploadContent {
    #md-prev-textarea {
      padding: 0px;
    }
    .textarea-only {
      padding: 10px !important;
    }
  }
  .editor {
    width: 100vw;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #666;
    box-sizing: border-box;
  }
}
</style>
